.site-header {
	position: absolute !important;
	top: 0;
	width: 100%;
	z-index: 10 !important;
	background: color-bg(header);

	+ .site-content {

		.section:first-of-type {
			padding-top: $header-height__mobile;
		}
	}

	.brand {
		margin-right: $header-nav--padding-h;
	}

	.brand a{
		text-decoration: none;
	}
}

.site-header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
	height: $header-height__mobile;
}

.header-nav {
	flex-grow: 1;

	.header-nav-inner {
		display: flex;
		flex-grow: 1;
	}

    ul {
        display: flex;
        align-items: center;
		flex-grow: 1;
        white-space: nowrap;
		margin-bottom: 0;

		&:first-of-type {
			flex-wrap: wrap;
		}
    }

    li {

		+ .header-button {
			margin-left: $header-nav--padding-h;
		}
    }

    a:not(.button) {
		display: block;
        @include anchor-aspect(header);
		@include font-weight(header-link);
		text-transform: $link-header--transform;
        padding: 0 $header-nav--padding-h;

		.invert-color & {
			@include anchor-aspect(header, inverse);
		}
    }

	a.button {
		margin-left: $header-nav--padding-h;
	}
}

.header-nav-center:first-of-type {
	flex-grow: 1;
	justify-content: flex-end;
}

.header-nav-right {
	justify-content: flex-end;

	+ .header-nav-right {
		flex-grow: 0;
	}
}

.header-nav-toggle {
	display: none;
}

@include media( '<=medium' ) {

	.header-nav-toggle {
		display: block;

		// Header navigation when the hamburger is a previous sibling
		+ .header-nav {
			flex-direction: column;
			position: absolute;
			left: 0;
			right: 0;
			top: 100%;
			z-index: 9999;
			background: color-bg(menu-mobile);
			max-height: 0;
	        opacity: 0;
	        overflow: hidden;
	        transition: max-height .25s ease-in-out, opacity .15s;

			&.is-active {
				opacity: 1;
			}

			.header-nav-inner {
				flex-direction: column;
				padding: $header-nav--padding-v__mobile;
			}

			ul {
				display: block;
				text-align: center;

			    a:not(.button) {
					display: inline-flex;
			        @include anchor-aspect(header-mobile);
					padding-top: $header-nav--padding-v__mobile / 2;
					padding-bottom: $header-nav--padding-v__mobile / 2;
			    }
			}

			a.button {
				margin-left: 0;
				margin-top: $header-nav--padding-v__mobile / 2;
				margin-bottom: $header-nav--padding-v__mobile / 2;
			}
		}
	}
}

@include media( '>medium' ) {

	.site-header {

		+ .site-content {

			.section:first-of-type {
				padding-top: $header-height__desktop;
			}
		}
	}

	.site-header-inner {
		height: $header-height__desktop;
	}
}
